import OrianaKou from "./ActiveHeadshots/Oriana Kou.webp";
import CurtisYang from "./ActiveHeadshots/Curtis Yang.webp";
import JessicaChoi from "./ActiveHeadshots/Jessica Choi.webp";
import AlexLi from "./ActiveHeadshots/Alex Li.webp";
import JustinLin from "./ActiveHeadshots/Justin Lin.webp";
import JacobMark from "./ActiveHeadshots/Jacob Mark.webp";
import VinhTran from "./ActiveHeadshots/Vinh Tran.webp";
import TiffanyVo from "./ActiveHeadshots/Tiffany Vo.webp";
import DominicPagano from "./ActiveHeadshots/Dominic Pagano.webp";
import AarushInamdar from "./ActiveHeadshots/Aarush Inamdar.webp";
import AlexKao from "./ActiveHeadshots/Alex Kao.webp";
import SarahKim from "./ActiveHeadshots/Sarah Kim.webp";
import YanAmyZhou from "./ActiveHeadshots/Yan Amy Zhou.webp";
import AlexaAncheta from "./ActiveHeadshots/Alexa Ancheta.webp";
import TiffanyCheng from "./ActiveHeadshots/Tiffany Cheng.webp";
import IzellaHan from "./ActiveHeadshots/Izella Han.webp";
import AnnaShan from "./ActiveHeadshots/Anna Shan.webp";
import IsabellaTan from "./ActiveHeadshots/Isabella Tan.webp";
import AmyTu from "./ActiveHeadshots/Amy Tu.webp";
import MasonWhang from "./ActiveHeadshots/Mason Whang.webp";
import EdmundMa from "./ActiveHeadshots/Edmund Ma.webp";
import ChrisWang from "./ActiveHeadshots/Chris Wang.webp";
import AndrewSou from "./ActiveHeadshots/Andrew Sou.webp";
import AnnieNguyen from "./ActiveHeadshots/Annie Nguyen.webp";
import AlexisLin from "./ActiveHeadshots/Alexis Lin.webp";
import CatelynnChen from "./ActiveHeadshots/Catelynn Chen.webp";
import ElleHsu from "./ActiveHeadshots/Elle Hsu.webp";
import AnujPatel from "./ActiveHeadshots/Anuj Patel.webp";
import RithikaPothi from "./ActiveHeadshots/Rithika Pothi.webp";
import DefaultHeadshot from "./ActiveHeadshots/Default Headshot.webp";

export const headshotHash = {
  "Aarush Inamdar": AarushInamdar,
  "Alex Kao": AlexKao,
  "Oriana Kou": OrianaKou,
  "Curtis Yang": CurtisYang,
  "Jessica Choi": JessicaChoi,
  "Alex Li": AlexLi,
  "Justin Lin": JustinLin,
  "Jacob Mark": JacobMark,
  "Vinh Tran": VinhTran,
  "Tiffany Vo": TiffanyVo,
  "Dominic Pagano": DominicPagano,
  "Sarah Kim": SarahKim,
  "Yan Amy Zhou": YanAmyZhou,
  "Alexandra Ancheta": AlexaAncheta,
  "Tiffany Cheng": TiffanyCheng,
  "Izella Han": IzellaHan,
  "Anna Shan": AnnaShan,
  "Isabella Tan": IsabellaTan,
  "Amy Tu": AmyTu,
  "Mason Whang": MasonWhang,
  "Edmund Ma": EdmundMa,
  "Chris Wang": ChrisWang,
  "Andrew Sou": AndrewSou,
  "Annie Nguyen": AnnieNguyen,
  "Alexis Lin": AlexisLin,
  "Catelynn Chen": CatelynnChen,
  "Elle Hsu": ElleHsu,
  "Anuj Patel": AnujPatel,
  "Rithika Pothi": RithikaPothi,
  "Default Headshot": DefaultHeadshot,
};
